<template>
  <f7-page class="scan-page">
    <f7-navbar back-link="Back"> </f7-navbar>

    <section class="custom-container">
      <div class="container">
          <p><img src="@/assets/images/wechat.png" loading="lazy"></p>
          <h1>Loading...</h1>
      </div>
    </section>
  </f7-page>
</template>

<script>
import { defineComponent, onMounted, inject} from "vue";
import { configs } from "@/utils/configs.js";
import { login as authLogin } from "@/logics/auth.js";
import { useStore } from "@/store";
import { $HTTP } from '@/utils/axios'
export default defineComponent({
  name: "WechatRedictPage",
  components: {},
  props: { f7router: Object, f7route: Object },
  setup(props) {
    const $t = inject("$translation")
    
    const store = useStore();
   
    const login = async(obj) => {
      if(obj && obj.Token) {
        obj.TokenStartDate = new Date().getTime();
        store.dispatch('user/setData', obj);
        store.dispatch("chat/connect")
        store.dispatch("cart/fetchData", { count : true })
        props.f7router.navigate("/");
        return;
      }else{
        throw new Error($t.getTranslation("ERR_WECHAT_LOGIN"));
      }
    }

    const checkToken = async(token) => {
      let check = await $HTTP.post(`/webhook/wechat/check/token`, {token: token});
      let decodedData = check.data.data;
      if (decodedData) {
       
        //add redirect to the register fill up..
        let names = ``;
        let obj = {};
        
        if (!decodedData.isUser) {
          names = decodedData.nickname;
          names = names.split(' ');
          obj = {
            FirstName: names[0],
            LastName: names[1],
            OpenId: decodedData.openid,
            Province: decodedData.province,
            City: decodedData.city,
            Country: decodedData.country
          }
          store.dispatch("register/setData", {
              ...obj,
              IsPrivacyAgreed: 1,
              IsTermsAgreed: 1,
              IsNewsletterSubscribed:1,
              IsOTPGenerated: 0
          });
          props.f7router.navigate("/register/mobileauthentication/");
        }else{
          await login(decodedData);
        }
      }
    }

    onMounted(async () => {
      if (props?.f7route?.query?.success == 1 || props?.f7route?.query?.success == '1') {
        await checkToken(props?.f7route?.query?.token);
      }else{
        props.f7router.navigate("/auth/login/");
        throw new Error($t.getTranslation("ERR_WECHAT_LOGIN"));
      }
    });
    return {
      checkToken, login
    };
  },
});
</script>

<style scoped>
  .container > h1{
    text-align: center;
  }
  section{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  p{
    text-align: center;
    padding: 0px;
    margin: 0px;
  }
  .container > p > img{
    width: 100px;
  }
</style>